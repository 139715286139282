import React from 'react';
import './App.less';
import {
    BrowserRouter as Router, Redirect, Route,
    Switch,
} from "react-router-dom";
import MainLayoutRoute from "@/router/MainLayoutRoute";
import Home from "@/pages/home";
import Solution from "@/pages/solution";
import Test from "@/pages/Test";


function App() {
    return (
        <div className="App">
            <Router>
                <Switch>
                    <MainLayoutRoute component={Home} exact={true} path='/'></MainLayoutRoute>
                    <MainLayoutRoute component={Home} path='/browser'></MainLayoutRoute>
                    <MainLayoutRoute component={Solution} path='/solution'></MainLayoutRoute>
                    <MainLayoutRoute component={Test} path='/test'></MainLayoutRoute>
                    <Route path="/*">
                        <Redirect to="/"></Redirect>
                    </Route>
                </Switch>

            </Router>

        </div>
    );
}

export default App;