interface Navigation {
    name:string,
    link:string,
    children?:Array<{
        name:string,
        link:string,
    }>
}
const CONSTANTS:Array<Navigation> =  [
    { name: "首页", link: "/" },
    // {
    //     name: "浏览器",
    //     link: "/browser",
    // },
    {
        name: "解决方案",
        link: "/solution",
    },

]
export default CONSTANTS;