import React, {useState} from "react";
import Illus_money from "@/assets/images/solution/illus_money@2x.png";
import Arrow from "@/assets/images/solution/arrow@2x.png";
import Sad from "@/assets/images/solution/sad@2x.png";
import Smile from "@/assets/images/solution/smile@2x.png";
import styles from './index.module.less'
import {COMPARATION} from "@/pages/solution/constants";

import Consult from "@/components/consult";



function Solution() {
    const [contactShow, setContactShow] = useState(false)



    return <div className={styles.container}>

        <div className={styles.header}>
            <div className="box-container">

                <div className={styles.intro}>
                    <div className={styles.introContent}>
                        <div className={styles.detailContainer}>
                            <div className={styles.detail}>
                                <div className={styles.title}>
                                    <div className={styles.mainTitle}>数据交易</div>
                                    <div
                                        className={styles.subTitle}>数据哈希链上铆定存储，链上授权和记录、链下数据交换，基于区块链进行细粒度权限控制，交易信息及计算过程信息上链，保证后期数据不可篡改，数据使用和流转可追溯审计，数据源的及计算过程可回溯验证。
                                    </div>
                                </div>

                                <div onClick={() => {
                                    setContactShow(true)
                                }} className={styles.contact}>联系我们
                                </div>
                            </div>

                        </div>
                        <div className={styles.introLogo}>
                            <img src={Illus_money} alt="intro"></img>
                        </div>
                    </div>

                </div>
            </div>

        </div>
        <div className={styles.advantage}>
            <div className="container">
                <div className="title">
                    <div className="content">方案优势</div>
                </div>
                <div className="content">
                    {
                        COMPARATION.map((item, index) => {
                            return <div key={index} className={styles.comparation}>
                                <div className="problem">
                                    <div className="header">
                                        <div className="title">
                                            {item.problem.title}</div>
                                        <div className="logo">
                                            <img src={Sad} alt="sad"></img>
                                        </div>
                                    </div>
                                    <div className="desc">
                                        {item.problem.desc}
                                    </div>
                                </div>
                                <div className="arrow">
                                    <img src={Arrow} alt="arrow"></img>
                                </div>
                                <div className="solutionContainer">
                                    <div className="solution"
                                         style={{

                                             backgroundImage:`url(${item.solution.bg})`,
                                             backgroundSize:'520px 180px',
                                             backgroundRepeat:'no-repeat',

                                         }}
                                    >

                                        <div className="header">
                                            <div className="title" >
                                                {item.solution.title}
                                            </div>
                                            <div className="logo">
                                                <img src={Smile} alt="smile"></img>
                                            </div>
                                        </div>
                                        <div className="desc" style={{color:'#FFFFFF'}}>
                                            {item.solution.desc}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        })
                    }
                </div>
            </div>


        </div>
        <div className={styles.structure}>
            <div className="container">
                <div className="title">
                    <div className="content">方案架构</div>
                </div>
                <div className="content">
                    <div className="structureContent"></div>
                </div>

            </div>
        </div>
        <Consult modalShow={contactShow} closeModal={
            () => {
                setContactShow(false)
            }
        }></Consult>


    </div>
}

export default Solution