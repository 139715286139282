// import axios from 'axios'
// export default 1

// 防抖
export function debounce(fn: any, t: number) {
  let timeId: NodeJS.Timeout | null = null
  const delay = t || 1000
  return function (this: any, ...args: any) {
    if (timeId) clearTimeout(timeId)
    timeId = setTimeout(() => {
      timeId = null
      fn.apply(this, args)
    }, delay)
  }
}


// 节流
export function throtle(fn: any, wait: number) {
  let canRun = true; // 通过闭包保存一个标记
  console.log(canRun)

  return function (this: any, ...args: any) {
      if (!canRun) return;
      
      canRun = false;
      console.log(canRun)
      setTimeout(() => {
        fn.apply(this, args);
        canRun = true; // 执行完毕后状态复原
      }, wait || 500)
  }
}