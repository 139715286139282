import React from 'react'
// import Swiper JS
import { Swiper, SwiperSlide } from "swiper/react";


// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"

import './swiperpage.less';
import {USAGE} from './constants'
import styles from "@/pages/home/index.module.less";
import SwiperCore, {Autoplay, EffectCoverflow, Navigation, Pagination} from 'swiper/core';

// configure Swiper to use modules
// install Swiper modules
SwiperCore.use([Autoplay,EffectCoverflow,Navigation]);
function SwiperPage(){
    return  <div className='swiper-page-container'>
        <div className="home-layer-content home-layer-report">
            <div className="swiper-container swiper-report" >
                <div>
                    <Swiper
                        watchSlidesProgress={true}
                        slidesPerView={'auto'}
                        centeredSlides={true}
                        loop={true}
                        loopedSlides={3}
                        autoplay={true}
                        onProgress={(swiper)=>{
                            const slides = swiper.slides
                            for (let i = 0; i < slides.length; i++) {
                                let slide = slides.eq(i);
                                let slideProgress = slides[i].progress;
                                let modify = 1;
                                if (Math.abs(slideProgress) > 1) {
                                    modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
                                }
                                let translate = slideProgress * modify * 546 + 'px';
                                let scale = 1 - Math.abs(slideProgress) / 5;
                                let zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
                                slide.transform('translateX(' + translate + ') scale(' + scale + ')');
                                slide.css('zIndex', zIndex);
                                slide.css('opacity', 1);
                                if (Math.abs(slideProgress) > 3) {
                                    slide.css('opacity', 0);
                                }
                            }
                        }
                        }
                        onSetTransition={(swiper,transition) =>{
                            console.log(swiper)

                            const slides = swiper.slides
                            for (let i = 0; i < slides.length; i++) {
                                let slide = slides.eq(i)
                                slide.transition(transition);
                            }

                        }}
                        navigation={
                            {
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev',
                            }
                        }
                    pagination={{
                        el: '.swiper-pagination',
                    }

                        //clickable :true,
                    }

                        // navigation={true}
                    >
                        {
                            USAGE.map(item=>{
                                return <SwiperSlide key={item.title}>
                                    <div className={styles.usage}>
                                        <div className="title">{item.title}</div>
                                        <div className="des">{item.des}</div>
                                        <div className="logo">
                                            <img src={item.photo} alt = {item.title}></img>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            })
                        }

                    </Swiper>
                </div>

            </div>

        </div>
    </div>

}
export default SwiperPage