import React, {useState} from 'react';
import styles from './Copyright.module.css';
import CONSTANTS from './constants';
import QRcode from '@/assets/images/footer/QRcode@2x.png'

function Copyright() {

    const [followActive, setFollowActive] = useState(-1);

    const changeFollow = (key:number)=>{
        setFollowActive(key)
    }

    return (<div className={styles.container}>
            <div className="box-container" >
                <div className={styles.content}>
                    <div className={styles.copyright}>
                        {
                            CONSTANTS.copyright

                        }<a  rel="noreferrer"  href='https://beian.miit.gov.cn/#/Integrated/index' target='_blank'> 浙ICP备2021006035号-1</a>
                    </div>

                    <div className={styles.qrCode}>
                        <div className={styles.qr}>
                            {
                                followActive === 0&& <img alt = "qrcode"  src ={QRcode}></img>

                            }
                        </div>

                        <div className={styles.iconContainer}>
                            {CONSTANTS.imgList.map((item, index) => {
                                return (
                                    <div
                                        className={styles.wechat}
                                        key={item.className}
                                        onMouseEnter={() => {
                                            changeFollow(index);
                                        }}
                                        onMouseOut={() => {
                                            changeFollow(1);
                                        }}
                                    >
                                        <img
                                            alt = "wechat"
                                            src={followActive === index ? item.activeImg : item.img}
                                        ></img>
                                    </div>
                                );
                            })}
                        </div>

                    </div>
                </div>

            </div>


    </div>

    );
}

export default Copyright;