import React, {useEffect, useState} from "react";
import styles from './index.module.less';
import Intro from '@/assets/images/home/intro.png'
import Base_bg from '@/assets/images/home/Base_bg@2x.png';
import Node from '@/assets/images/home/node@2x.png';
import Data_market from '@/assets/images/home/Data market@2x.png';
import {CHAINS,USAGE} from './constants'
// @ts-ignore
import { Swiper, SwiperSlide } from "swiper/react";


// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
// @ts-ignore
import {animated, config, useSpring} from "react-spring";

import SwiperCore, {
    Autoplay,Navigation,EffectCoverflow,
} from 'swiper/core';
import Consult from "@/components/consult";
import SwiperPage from './SwiperPage'
import axios from "axios";


// install Swiper modules
SwiperCore.use([Autoplay,EffectCoverflow,Navigation]);


function Home(){
    // const [flip,setFlip]= useState(false)
    const [contactShow, setContactShow] = useState(false)
    const [chains, setChains] = useState(CHAINS)
    const props = useSpring({
        to:{opacity:1} ,
        from:{opacity:0,},
        delay:200,
        config:config.molasses,

    })
    const subTitleProps = useSpring({
        to:{opacity:1,transform: 'translate3d(0,0,0)'} ,
        from:{opacity:0,transform: 'translate3d(0,60%,0)'},
        delay:450,
        config:config.molasses,

    })
    // const imageProps = useSpring({
    //     to:{ transform: 'rotate(0deg)'},
    //     from:{transform: 'rotate(40deg)'},
    //     reverse: flip,
    //     reset:true,
    //     delay: 100,
    //     config: config.molasses,
    //     onRest: () => setFlip(!flip),
    // })
    useEffect(()=>{
        axios.get('/api/back/blockDashboard/get/1').then(res=>{
            const {code,data} = res.data
            if(code === 200){
                CHAINS[0].num = data.blockHeight
                CHAINS[1].num = data.connectPeerNu
                CHAINS[2].num = data.contractNu
                CHAINS[3].num = data.txNu
                setChains([...CHAINS])
            }
        }).catch(e=>{
            console.log(e)
        })
    },[])

    return<div className={styles.container}>
        <div className={styles.header}>
            <div className="box-container">

                <div className={styles.intro}>
                    <div className={styles.introContent}>
                        <div className={styles.detailContainer}>
                            <div className={styles.detail}>
                                <div className={styles.title}>
                                    <div className={styles.mainTitle}>
                                       <animated.div style={props}>
                                       数易信</animated.div>
                                    </div>
                                    <div className={styles.subTitle}>
                                    <animated.div
                                        style={subTitleProps}
                                    >

                                            建设具有监管效力、权威公信的交易标的、交易关系、交易过程的天枢链应用
                                    </animated.div>
                                    </div>

                                </div>

                                <div onClick={()=>{setContactShow(true)}} className={styles.contact}>联系我们</div>
                            </div>

                        </div>
                        <div className={styles.introLogo}>

                            <img src={Intro} alt = "intro"></img>

                        </div>
                    </div>

                </div>
                <div className={styles.base}>
                    <div className={styles.baseHeader}>
                        {
                            chains.map((item,index)=>{
                                return <div  key={item.text} className={styles.baseItem}>
                                    <div className={styles.baseItemHeader}>{item.num}</div>
                                    <div className={styles.baseItemFooter}>
                                        <div  className={styles[`baseIcon${index}`]}><img alt={item.text}  src={item.icon}></img></div>
                                        <div>{item.text}</div>
                                    </div>
                                </div>
                            })
                        }
                    </div>

                    <img src={Base_bg} alt = "base_bg"></img>
                </div>
            </div>

        </div>
        <div className="box-container">
            <div className={styles.chainNode}>
                <div className="title">链上节点</div>
                <div className="content">
                    <div className="intro">
                        <div className="item">
                            天枢链联合杭州市知识产权中心、浙江金融控股集团、天枢数链组成数据交易可信联盟，即数易信平台。
                        </div>
                        <div className="item">
                            其他权威机构、企业主体等可通过加入天枢链联盟监管或上传平台业务数据，获得权威认证，也可以通过使用在天枢链基础上开发的各种业务应用进行业务数据上链，实现业务需要。
                        </div >
                        <div className="item">
                            联盟主要目标是建设具有监管效力、权威公信的主要以数据等交易标的、交易关系、交易过程的区块链底层基础设施
                        </div>
                    </div>

                        <div className="logo"
                        >

                            <img src={Node} alt="node"></img>
                        </div>



                </div>

            </div>
        </div>
        <div className={styles.example}>
            <div className="title">
                应用案例
            </div>
            <div className="station"/>
            <div className="content">
                <div className="box-container">

                    <div className="container">
                        <div className="logo">
                            <img src={Data_market} alt = "data_market"></img>
                        </div>
                        <div className="info">
                            <div className="header">数易信+数据要素交易市场</div>
                            <div className="footer">当前天枢金通数据要素交易市场所有的数据指纹、数据商品信息、数据交易订单信息、数据交易过程信息均已上链，围绕数据交易生态圈打造权威、可信的数据流通联盟。</div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <div className={styles.swiper}>
            <div className="swiperTitle">
                应用场景
            </div>
            {/*<div className="swiper-button-prev"><LeftOutlined style={{color: 'black'}} /></div>*/}
            {/*<div className="swiper-button-next"><RightOutlined style={{color: '#4E576B'}} /></div>*/}

            <SwiperPage></SwiperPage>


        </div>
        <Consult modalShow={contactShow} closeModal={()=>{setContactShow(false)}}></Consult>


    </div>
}
export default Home;