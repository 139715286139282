import React, {useEffect, useState} from "react";
import styles from './index.module.less'
import LOGO from "@/assets/images/header/logo@2x.png";
import { useHistory } from "react-router-dom";
import CONSTANTS from "./constants";
import './reset.less'
import {Menu} from 'antd'
const {SubMenu} = Menu;

function Header(){
    const [isScroll, setIsScroll] = useState(0);
    let history = useHistory();
    const onScroll = () => {
        let scrolled =
            document.documentElement.scrollTop || document.body.scrollTop;
        setIsScroll(scrolled);
    };
    const goTo = (e:any)=>{

        //
        // const url = window.location.href
        // console.log(e,url,history.location)
     
        if(e.key=== history.location.pathname){
            history.go(0)
        }else{
            history.push(e.key);
        }
        // console.log(e)



    }
    useEffect(() => {
        document.addEventListener("scroll", onScroll);
        return () => {
            document.removeEventListener("scroll", onScroll);
        };
    }, []);
    // const goto = (e)=>{
    //
    // }
    return<div className={styles[`${isScroll > 0?'isScroll':'container'}`]}>
        <div className={`box-container ${styles.content}`}>
            <div className={styles.logo}>
                <img src={LOGO} alt = "logo"></img>

            </div>
            <div className={styles.navigation}>

                <Menu
                    onClick={(e)=>{goTo(e)}}
                    mode="horizontal"
                      theme={`${
                          isScroll > 0 ? "light" : "dark"
                      }`}
                >
                    {
                        CONSTANTS.map((item) =>
                            item.children ? (
                                <SubMenu  title={item.name} key={item.link}>
                                    {item.children.map((subItem, ind) => (
                                        <Menu.Item   key={subItem.link}>{subItem.name}</Menu.Item>
                                    ))}
                                </SubMenu>
                            ) : (
                                <Menu.Item key={item.link}>{item.name}</Menu.Item>
                            )
                        )
                    }
                </Menu>

            </div>
        </div>

    </div>
}
export default Header