import React from "react";
import {Form, Input, message, Modal} from "antd";
import styles from "./index.module.less";
import axios from "axios";
import { debounce } from "../../utils"

interface Props{
    modalShow:boolean,
    closeModal:()=>void
}
function Consult(props:Props){
    // companyName	string 公司名称
    // contactReason	string联系事由
    //linkEmail	string联系邮箱
    //linkMobile	string联系电话
    // linkName	string联系人
    // source
    const {modalShow,closeModal} = props
    const [form] = Form.useForm()
    const submit =  debounce(() => {
        const values = form.getFieldsValue()
        const {linkName,linkMobile,contactReason} = values

        if(!linkName){
            message.warn('需要填写联系人').then()
        }else if(!linkMobile){
            message.warn('需要填写手机号').then()
        }else if(!contactReason){
            message.warn('需要填写联系事由').then()
        }else if(!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(linkMobile)){
            message.warn('不是合法的手机号').then()
        } else{
            // axios.get('')
            axios.post('/api/back/contactSource/add',{
                ...values,source:'syx'
            }).then(res=>{
                const {code,msg} = res.data
                if(code === 200){
                    message.success(msg).then()

                }
            }).finally(()=>{
                closeModal()
                form.resetFields()
            })
        }
    }, 500)
    return<div>
        <Modal
            width="975px"
            bodyStyle={{
                padding: 0,
                width: '975px',
                height: '548px'
            }
            }
            footer={null}
            visible={modalShow} onCancel={() => {
            form.resetFields()
            closeModal()
        }}>
            <div className={styles.consult}>
                <div className="info">
                    <div className="title">我们的优势</div>
                    <div className="footer">
                        <ul>
                            <li>数据资源安全共享</li>
                            <li>数据操作流程追溯</li>
                            <li>数据交易可信环境生成</li>
                            <li>提高交易效率，提供安全交易保障</li>
                        </ul>
                    </div>
                </div>
                <div className="contact">
                    <div className="title">免费咨询数据交易解决方案</div>
                    <Form form={form} colon={false}>
                        <Form.Item label="联系人"
                                   name = "linkName"
                                   required={true}
                                   rules={[{required: true,message:''}]}
                        >
                            <Input placeholder="怎么称呼您"></Input>
                        </Form.Item>
                        <Form.Item label="联系电话"
                                   name = "linkMobile"
                                   required={true}
                                   rules={[{required: true,message:''}]}
                        >
                            <Input placeholder="怎么联系您"></Input>
                        </Form.Item>
                        <Form.Item label="联系事由"
                                   name = "contactReason"
                                   required={true}
                                   rules={[{required: true,message:''}]}
                        >
                            <Input placeholder="您想要了解什么"></Input>
                        </Form.Item>
                        <Form.Item label="联系邮箱" name = "linkEmail">
                            <Input placeholder="您的邮箱"></Input>
                        </Form.Item>
                        <Form.Item label="公司名称" name = "companyName">
                            <Input placeholder="您的公司名称"></Input>
                        </Form.Item>
                    </Form>
                    <div className="submitButton" onClick={()=>{ submit() }}>提交</div>
                </div>
            </div>

        </Modal>
    </div>
}
export default Consult