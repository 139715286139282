import Advantage_bg_01 from '@/assets/images/solution/Advantage_bg_01@2x.png'
import Advantage_bg_02 from '@/assets/images/solution/Advantage_bg_02@2x.png'
import Advantage_bg_03 from '@/assets/images/solution/Advantage_bg_03@2x.png'
export const COMPARATION = [
    {
        problem:{
            title:'数据难以确权',
            desc:'数据权属界定不明确造成数据在流通、交易、使用过程中的可解释空间大，导致市场规范性变差'
        },
        solution:{
            bg:Advantage_bg_01,
            title:'数据资源安全共享',
            desc:'结合数字身份、异构数据整合、数据隐私保护等基础技术能力，支持数据接口、调用等多种共享交换模式，通过国密算法满足数据全链路加密要求',
        }
    },
    {
        problem:{
            title:'数据流通难以监管',
            desc:'由于数据的可复制性、可修改性特点，使得数据的流通存在监管难题，数据是否被多次出售难以监管。'
        },
        solution:{
            bg:Advantage_bg_02,
            title:'数据操作流程追溯',
            desc:'对数据资源的授权、调用操作进行全过程上链，实现授权方、使用方、调用方的操作行为透明可监管，支持事后监管审计'
        }
    },
    {
        problem:{
            title:'数据交易过程难以验证',
            desc:'隐私计算解决了数据输入的安全问题，但无法对计算的数据源是否发生了修改、计算过程是否满足需求发起方等无法验证。'
        },
        solution:{
            bg:Advantage_bg_03,
            title:'数据交易可信环境生成',
            desc:'开放灵活、简单易用的一站式区块链开放服务平台，帮助政府、企业快速轻松的搭建各类业务场景下的区块链应用'
        }
    },
]