import {Route} from "react-router-dom";
import React from "react";
import MainLayout from "@/layout/MainLayout";

const MainLayoutRoute = ({ component:Component, ...rest }: { component:React.ElementType,path:string,exact?:boolean }) => {
    return (
        <Route

            {...rest}
            render={(props) => (
                <MainLayout>
                   <Component></Component>
                </MainLayout>
            )}
        />
    );
};
export default MainLayoutRoute