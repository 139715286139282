import Base_icon_01 from '@/assets/images/home/Base_icon_01@2x.png';
import Base_icon_02 from '@/assets/images/home/Base_icon_02@2x.png';
import Base_icon_03 from '@/assets/images/home/Base_icon_03@2x.png';
import Base_icon_04 from '@/assets/images/home/Base_icon_04@2x.png';
import Image_01 from '@/assets/images/home/image_01@2x.png';
import Image_02 from '@/assets/images/home/image_02@2x.png';
import Image_03 from '@/assets/images/home/image_03@2x.png';
import Image_04 from '@/assets/images/home/image_04@2x.png';

export const CHAINS = [
    {
        num: 672991,
        text: '区块高度',
        icon: Base_icon_01
    },
    {
        num: 3,
        text: '可信节点数',
        icon: Base_icon_02
    },
    {
        num: 1,
        text: '运行合约数',
        icon: Base_icon_03
    },
    {
        num: 3,
        text: '上链交易数量',
        icon: Base_icon_04
    },

]
export const USAGE = [
    {
        title: '数易信+电商',
        des: '通过将商户发布的商品信息上链，实现商品原始信息记录，确保商品信息真实性，避免商家为了商品刚发布时博取流量而进行虚假宣传，误导消费者。',
        photo: Image_01
    },
    {
        title: '数易信+产权交易',
        des: '实现链上交易及交易信息上链，通过在链上交易触发产权实质性转移，记录交易发生的对象、时间、标的，后续如产生纠纷可作为证据判断。',
        photo: Image_02
    },

    {
        title: '数易信+大宗商品贸易',
        des:'链上同步国际大宗商品流通状态，将各环节物流、商流、资金流信息上链，避免中间环节信息不流通、不同步、不公开导致的货物损失等情况。',
        photo: Image_03
    },
    {
        title: '数易信+大众艺术创作',
        des:'可实现原创作品上传后确权，并在链上进行分发交易，通过智能合约自动授权用户使用作品，并核定收益。',
        photo: Image_04
    },
]
