import React, {useEffect, useState} from "react";
import {animated, config, useSpring} from "react-spring";
import axios from "axios";
import styles from './Test.module.less'
import SwiperPage from "@/pages/home/SwiperPage";

function Test(){
    const [flip,setFlip]= useState(false)
    useEffect(()=>{
        axios.get('/api/back/blockDashboard/get/1').then()
    },[])
    const props = useSpring({
        to:{opacity:1,color:'blue', transform: 'translate3d(0,0,0)'},
        from:{opacity:0,color:'red',transform: 'translate3d(0,-20%,0)'},
        reset:true,
        reverse:flip,
        delay:200,
        config:config.molasses,
        onRest:()=>setFlip(!flip)
    })
    return<div className={styles.container}>
        {/*<animated.h1 style={props}>*/}
        {/*    Test*/}

        {/*</animated.h1>*/}
        <div className={styles.content}>
            <SwiperPage></SwiperPage>

        </div>
    </div>
}
export default Test