import Footer from "@/components/footer";
import React from 'react';
import Header from "@/components/header";
import './MainLayout.less'
interface AppProps {
    value?: string;
    children?: React.ReactNode; // 自己定义children的类型
}
const MainLayout = ({ children, ...rest }:AppProps) => {
    return (
        <div className="MainLayoutContainer">
            <div className="MainLayoutHeader">
                <Header></Header>
            </div>

            {children}
            <Footer></Footer>
        </div>
    );
};



export default MainLayout;