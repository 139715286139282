import React from 'react';
import styles from './index.module.css';
import CONSTANTS from './constants'

import Copyright from "@/components/footer/Copyright";

function Footer() {
    const goLink = (link:string) => {
        if (link && link.indexOf("http") > -1) {
            window.open(link);
        } else {
            // setRoute(link);
        }
    };
    return (
        <div className={styles.wrapContainer}>
            <div className="box-container">
                <div className={styles.content}>
                    <div className={styles.intro}>
                        <div className={styles.logo}>
                            <img src={CONSTANTS.logo} alt="logo"></img>
                        </div>
                        <div className={styles.company}>
                            <div>
                                {CONSTANTS.company}
                            </div>
                        </div>
                        <div className={styles.contact}>
                            {
                                CONSTANTS.contact.linkList.map(item=>{
                                    return <div className={styles.contactItem} key={item.text}>
                                        <div className={styles.contactItemIconContainer}>
                                            <img src={item.link} alt={item.text}></img>
                                        </div>
                                        <div className={styles.contactItemText}>
                                            {item.text}
                                        </div>
                                    </div>
                                })
                            }
                        </div>


                    </div>
                    <div className={styles.links}>
                        {
                            CONSTANTS.listData.map(item=>{
                                return <div key={ item.title} className={styles.itemContainer}>
                                    <div  className={styles.linksItem}>{item.title}</div>
                                    {
                                        item.linkList.map(single=>{
                                            return <div
                                                onClick={() => goLink(single.link)}
                                                className={styles.linksItemContent} key={single.text}>{single.text}</div>
                                        })
                                    }

                                </div>
                            })

                        }

                    </div>
                </div>
                <Copyright></Copyright>
            </div>

        </div>
    );
}

export default Footer;