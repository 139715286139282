import LOGO from '@/assets/images/header/logo.png';
import HOME_ICON_POSITION from '@/assets/images/footer/home_icon_position@2x.png';
import HOME_ICON_PHONE from '@/assets/images/footer/home_icon_phone@2x.png';
import HOME_ICON_COMPANY from '@/assets/images/footer/home_icon_company@2x.png';
import HOME_ICON_WECHAT_BEFORE from '@/assets/images/footer/home_icon_wechat_before@2x.png';
import HOME_ICON_WECHAT_AFTER from '@/assets/images/footer/home_icon_wechat_after@2x.png';

const CONSTANTS = {
    logo: LOGO,
    company: "天枢数链（浙江）科技有限公司",
    listData: [
        {
            title: "核心产品",
            linkList: [
                {
                    text: "天智产融",
                    link: "https://www.tssldc.com/product/tianzhi",
                },
                {
                    text: "天枢金通",
                    link: "https://www.tssldc.com/product/jintong",
                },
                {
                    text: "浙天图",
                    link: "https://www.tssldc.com/product/tiantu",
                },
                {
                    text: "开放平台",
                    link: "https://open.tssldc.com/",
                },
                {
                    text: "天枢链底层平台",
                    link: "https://tssldc.com/product/tianshulian"
                },
                {
                    text: "天枢链BaaS",
                    link: "https://tssldc.com/product/tianshubaas"
                },
                {
                    text: "数易信",
                    link: "https://tssldc.com/product/shuyixin"
                }
            ],
        },
        {
            title: "友情链接",
            linkList: [
                {
                    text: "浙江金控",
                    link: "https://zjfh.cn/",
                },
                {
                    text: "数秦科技",
                    link: "https://www.dataqin.com/",
                },
                {
                    text: "保全网",
                    link: "https://www.baoquan.com/",
                },
            ],
        },
        {
            title: "关于我们",
            linkList: [
                {
                    text: "公司介绍",
                    link: "https://www.tssldc.com/about#introduce",
                },
                {
                    text: "联系我们",
                    link: "https://www.tssldc.com/about#contact",
                },
                {
                    text: "加入我们",
                    link: "https://www.tssldc.com/about#join",
                },
            ],
        },
    ],
    contact: {
        title: "",
        linkList: [
            {
                link:HOME_ICON_POSITION,
                text: "杭州市下城区环城北路169号汇金国际大厦B座8层",
            },
            {
                link: HOME_ICON_PHONE,
                text: "0571-28221076",
            },
            {
                link: HOME_ICON_COMPANY,
                text: "business@tssldc.com",
            },
        ],
    },
    copyright:
        "天枢数链（浙江）科技有限公司所有｜ ",
    imgList: [
        {
            className: "wechat",
            img: HOME_ICON_WECHAT_BEFORE,
            activeImg: HOME_ICON_WECHAT_AFTER,
        },
    ],
}
export default CONSTANTS